<template>
    <div class="transactions-table-mobile-item" v-if="isTransactions">
        <h1 class="mobile-item-package-name">{{ row.packageName }}</h1>
        <div class="mobile-item">
            <h3 class="mobile-item-header">{{ headers[0] }}</h3>
            <p class="mobile-item-value">{{ row.transactionId }}</p>
        </div>
        <div class="mobile-item">
            <h3 class="mobile-item-header">{{ headers[2] }}</h3>
            <p class="mobile-item-value">{{ row.type }}</p>
        </div>
        <div class="mobile-item">
            <h3 class="mobile-item-header">{{ headers[3] }}</h3>
            <p class="mobile-item-value">${{ row.amount }}</p>
        </div>
        <div class="mobile-item flex">
            <div>
                <h3 class="mobile-item-header">{{ headers[4] }}</h3>
                <p class="mobile-item-value">{{ row.status }}</p>

            </div>
            <div>
                <h3 class="mobile-item-header">{{ headers[5] }}</h3>
                <p class="mobile-item-value">{{ row.createdDate }}</p>

            </div>
        </div>
        <div class="mobile-item">
            <h3 class="mobile-item-header">{{ headers[6] }}</h3>
            <p class="mobile-item-value">${{ row.note }}</p>
        </div>
    </div>
    <div class="transactions-table-mobile-item" v-else>
        <h1 class="mobile-item-package-name">{{ row.packageName }}</h1>
        <div class="mobile-item">
            <h3 class="mobile-item-header">{{ headers[0] }}</h3>
            <p class="mobile-item-value">{{ row.subscriptionId }}</p>
        </div>
        <div class="mobile-item">
            <h3 class="mobile-item-header">{{ headers[2] }}</h3>
            <p class="mobile-item-value">{{ row.startDate }}</p>
        </div>
    </div>  
</template>

<script>

export default {
    props: {
        row: Object,
        headers: Array,
        isTransactions: Boolean
    }
}
</script>

<style scoped>
h1,
h3,
p,
button,
input,
label,
a,
th,
td {
    font-family: 'Wix Madefor Display', sans-serif;
    color: #AAAFC1;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.64px;
    margin: 0;
}

.transactions-table-mobile-item {
    border-radius: 12px;
    padding: 16px;
    background-color: #141417;
    margin-bottom: 16px;
}

.mobile-item-package-name {
    font-size: 16px;
    font-weight: 600;
    line-height: 20.16px;
    margin-bottom: 12px;
}

.mobile-item-header {
    font-size: 13px;
    font-weight: 500;
    color: #AAAFC199;
    line-height: 16.38px;
    margin-bottom: 4px;
}

.mobile-item-value {
    font-size: 14px;
    font-weight: 500;
    color: #AAAFC1;
    line-height: 17.64px;
}

.mobile-item {
    margin-bottom: 12px;
}

.mobile-item.flex {
    display: flex;
    justify-content: space-between;
}

.mobile-item.flex div {
    width: 40%;
}</style>